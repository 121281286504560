var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c(
          "c-card",
          { attrs: { title: "미리보기", height: "470px", useCollapse: false } },
          [
            _c(
              "template",
              { slot: "card-button" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _c("c-btn", {
                      attrs: { label: "출력", color: "black", icon: "print" },
                      on: { btnClicked: _vm.getList },
                    }),
                    _c("c-btn", {
                      attrs: {
                        color: "purple",
                        label: "크게보기",
                        icon: "zoom_in",
                      },
                      on: { btnClicked: _vm.openPopup },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("template", { slot: "card-detail" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("q-img", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      src: "https://source.unsplash.com/random",
                      "spinner-color": "white",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }